function App() {
	const typesOfWork = [
		'Bytová elektroinstalace',
		'Elektroinstalace v domech včetně údržby',
		'Elektroinstalace v průmyslových objektech',
		'Instalace a opravy elektrických strojů',
		'Výměna kabeláže, rozvaděčů, přístrojů',
		'Revize a zkoušky elektrických zařízení',
	];
	// Montáž, opravy, revize a zkoušky elektrických zařízení

	return (
		<>
			<div className='container'>
				<div style={{ margin: '50px 0' }} className='text-right'>
					<div className='row'>
						<h1 className='offset-md-2 col-md-10 col-sm-12'>Dočkal Stavební s.r.o</h1>
					</div>
					<div className='row'>
						<h2 className='offset-md-2 col-md-10 col-sm-12'>Montáž, opravy, revize a zkoušky elektrických zařízení</h2>
					</div>
				</div>
				<div className='row justify-content-around'>
					<div className='card border-secondary mb-3 col-lg-5 col-md-6 col-sm-12'>
						<div className='card-header' style={{ color: 'black' }}>
							Elektroinstalace
						</div>
						<div className='card-body text-secondary'>
							<div className='card-text'>
								<ul>
									{typesOfWork.map((typeOfWork) => (
										<li key={typeOfWork}>{typeOfWork}</li>
									))}
								</ul>
							</div>
						</div>
					</div>

					<div className='card border-secondary mb-3 col-lg-5 col-md-6 col-sm-12'>
						<div className='card-header' style={{ color: 'black' }}>
							Kontakt
						</div>
						<div className='card-body text-secondary'>
							<h5 className='card-title'>Martin Dočkal</h5>
							<div className='card-text'>
								<div>+420 723 285 971</div>
								<div>martin@dockal.se</div>
								<div>IČO: 14037181</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div style={{ marginBottom: 200 }} />

			<footer className='bg-dark text-center text-white fixed-bottom'>
				<div className='text-center p-3'>Společnost zapsaná v obchodním rejstříku vedeném Městským soudem v Praze, oddíl C, vložka 359317</div>
			</footer>
		</>
	);
}

export default App;
